import React from "react"

import SEO from "../components/seo"
import Img from "gatsby-image"
import "./mystyles.scss"
import { useStaticQuery, graphql } from "gatsby"

const IndexPage = () => {
  const imageQuery = useStaticQuery(graphql`
  {
    logo: file(base: {eq: "logo.png"}) {
      childImageSharp {
        fluid(maxWidth:672, maxHeight:186) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    background: file(base: {eq: "background2.jpg"}) {
      childImageSharp {
        fluid(maxWidth:1920, maxHeight:1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
  `)

  return (
    <div>
      <Img fluid={imageQuery.background.childImageSharp.fluid}
        style={{
          backgroundPosition: `center center`,
          backgroundRepeat: `no-repeat`,
          backgroundAttachment: `fixed`,
          backgroundSize: `cover`,
          backgroundColor: `#999`,
          filter: `blur(2px)`,
          WebkitFilter: `blur(2px)`,
          position: `absolute`,
          height: `100%`,
          width: `100%`,
          top: 0,
        }}/>
      <section
        className="hero is-fullheight">
        <SEO title="Home" />
        <div className="hero-body">
          <div className="container">
            <div className="columns">
              <div className="column is-half">
                <figure className="image">
                  <Img fluid={imageQuery.logo.childImageSharp.fluid} />
                </figure>
              </div>
            </div>
            <h1 className="title has-text-light">
              Redefining the way you do business
            </h1>
            <h2 className="subtitle has-text-light">
              We are still in stealth mode, check back soon
            </h2>
          </div>
        </div>
      </section>
    </div>
  )
}

export default IndexPage
